// src/store/AppStore.tsx
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// interface AppStore {
// 	isDarkMode: boolean;
// 	toggleDarkMode: () => void;
// 	user: { email: string | null; role: string | null };
// 	setUser: (user: { email: string | null; role: string | null }) => void;
// 	clearUser: () => void;
// }

const useAppStore = create(
	persist(
		(set) => ({
			isDarkMode: false,
			toggleDarkMode: () => set((state) => ({ isDarkMode: !state.isDarkMode })),
			user: { email: null, role: null },
			setUser: (user) => set({ user }),
			clearUser: () => set({ user: { email: null, role: null } }),
		}),
		{
			name: "user", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
		},
	),
);

export default useAppStore;
