/* eslint-disable @typescript-eslint/no-explicit-any */

import useAxiosPrivate from "@/hooks/useAxiosPrivate";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";

import { countRecentUsers, formatDate } from "@/utils";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Users from "./Users/Users";

export default function DashboardPage() {
	const [users, setUsers] = useState([] as any[]);
	const [mpUsers, setMpUsers] = useState([] as any[]);
	const [calls, setCalls] = useState([]);
	const [mpCalls, setMpCalls] = useState([]);

	const { search } = useLocation(); // get the search (query string) from the location

	// check if existing search
	const queryParams = useMemo(() => search, [search]);

	const tab = useMemo(() => {
		const params = new URLSearchParams(queryParams);
		if (!params.has("tab")) return "overview";
		return params.get("tab");
	}, [queryParams]);

	function handleTabChange(tab: string) {
		const params = new URLSearchParams(queryParams);
		params.set("tab", tab);
		navigate(`?${params.toString()}`, { replace: true });
	}

	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const getUsers = async () => {
			// console.log("getUsers");
			try {
				const response = await axiosPrivate.get("/auth/users", {
					signal: controller.signal,
				});

				isMounted && setUsers(response?.data?.users);
				isMounted &&
					setCalls(
						response?.data?.calls?.sort((a: any, b: any) => b.id - a.id),
					);
				isMounted && setMpUsers(response?.data?.mpUsers);
				isMounted &&
					setMpCalls(
						response?.data?.mpCalls?.sort((a: any, b: any) => b.id - a.id),
					);
			} catch (err) {
				console.error(err);
				navigate("/login", { state: { from: location }, replace: true });
			}
		};

		getUsers();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	async function changeAccountType(email: string, account: string) {
		try {
			axiosPrivate.post("/auth/users", {
				email,
				account,
			});
		} catch (err) {
			console.error(err);
		}
	}

	return (
		<>
			<div className="flex-1 space-y-4 p-8 pt-6">
				<div className="flex items-center justify-start space-y-2">
					<h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>

					<div className="flex items-center space-x-2">
						{/* <CalendarDateRangePicker />
              <Button>Download</Button> */}
					</div>
				</div>
				<Tabs
					defaultValue="overview"
					value={tab!}
					onValueChange={(value) => handleTabChange(value)}
					className="space-y-4"
				>
					<TabsList>
						<TabsTrigger value="overview">Overview</TabsTrigger>
						{/* <TabsTrigger value="analytics" disabled>
                Analytics
              </TabsTrigger> */}
						<TabsTrigger value="users">Users</TabsTrigger>
						{/* <TabsTrigger value="reports" disabled>
                Reports
              </TabsTrigger>
              <TabsTrigger value="notifications" disabled>
                Notifications
              </TabsTrigger> */}
					</TabsList>
					<TabsContent value="overview" className="space-y-4 tabs-cont">
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">Χρήστες</CardTitle>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										className="h-4 w-4 text-muted-foreground"
									>
										<path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
										<circle cx="9" cy="7" r="4" />
										<path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
									</svg>
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										{users?.length > 0 && users?.length}
									</div>
									<p className="text-xs text-muted-foreground">
										{countRecentUsers(users)} νέοι χρήστες τις τελευταίες 30
										ημέρες
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">Έλεγχοι</CardTitle>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										className="h-4 w-4 text-muted-foreground"
									>
										<rect width="20" height="14" x="2" y="5" rx="2" />
										<path d="M2 10h20" />
									</svg>
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										{calls?.length > 0 && calls?.length}
									</div>
									<p className="text-xs text-muted-foreground">
										{countRecentUsers(calls)} Έλεγχοι τις τελευταίες 30 ημέρες
									</p>
								</CardContent>
							</Card>

							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Χρήστες MyPortal
									</CardTitle>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										className="h-4 w-4 text-muted-foreground"
									>
										<path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
										<circle cx="9" cy="7" r="4" />
										<path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
									</svg>
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										{mpUsers?.length > 0 && mpUsers?.length}
									</div>
									<p className="text-xs text-muted-foreground">
										{countRecentUsers(mpUsers)} νέοι χρήστες τις τελευταίες 30
										ημέρες
									</p>
								</CardContent>
							</Card>
							<Card>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium">
										Έλεγχοι MyPortal
									</CardTitle>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										className="h-4 w-4 text-muted-foreground"
									>
										<rect width="20" height="14" x="2" y="5" rx="2" />
										<path d="M2 10h20" />
									</svg>
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold">
										{mpCalls?.length > 0 && mpCalls?.length}
									</div>
									<p className="text-xs text-muted-foreground">
										{countRecentUsers(mpCalls)} Έλεγχοι τις τελευταίες 30 ημέρες
									</p>
								</CardContent>
							</Card>
						</div>
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-8">
							<Card className="col-span-4">
								<CardHeader>
									<CardTitle>Τελευταίες Καταχωρήσεις AlmaProperty</CardTitle>
								</CardHeader>
								<CardContent className="pl-2">
									<Table>
										<TableHeader>
											<TableRow>
												<TableHead className="max-w-[250px]">Χρήστης</TableHead>

												<TableHead className="text-right">Ημερομηνία</TableHead>
											</TableRow>
										</TableHeader>
										<TableBody>
											{calls?.slice(0, 10).map(
												(
													invoice: {
														user_id: number;
														created_at: string;
														email: string;
													},
													index,
												) => (
													<TableRow key={`calls-${index}`}>
														<TableCell className="font-medium">
															{invoice?.email}
														</TableCell>

														<TableCell className="text-right">
															{formatDate(invoice.created_at, {
																simple: true,
																withTime: true,
															})}
														</TableCell>
													</TableRow>
												),
											)}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
							<Card className="col-span-4">
								<CardHeader>
									<CardTitle>Τελευταίες Καταχωρήσεις MyPortal</CardTitle>
								</CardHeader>
								<CardContent className="pl-2">
									<Table>
										<TableHeader>
											<TableRow>
												<TableHead className="max-w-[250px]">Χρήστης</TableHead>

												<TableHead className="text-right">Ημερομηνία</TableHead>
											</TableRow>
										</TableHeader>
										<TableBody>
											{mpCalls?.slice(0, 10).map(
												(
													invoice: {
														user_id: number;
														created_at: string;
														email: string;
													},
													index,
												) => (
													<TableRow key={`mpcalls-${index}`}>
														<TableCell className="font-medium">
															{invoice?.email}
														</TableCell>

														<TableCell className="text-right">
															{formatDate(invoice.created_at, {
																simple: true,
																withTime: true,
															})}
														</TableCell>
													</TableRow>
												),
											)}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</div>
					</TabsContent>
					<TabsContent value="users" className="space-y-4">
						<Users
							{...{ users, calls, mpUsers, mpCalls }}
							changeAccountType={changeAccountType}
						/>
					</TabsContent>
				</Tabs>
			</div>
		</>
	);
}
