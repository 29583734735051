import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { el } from "date-fns/locale";
registerLocale("el", el);

import { addOrganization } from "@/api/organizationApi";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";

import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { validateAFM } from "@/utils";
import { UserAddIcon } from "@heroicons/react/outline";
import {
	Button,
	Divider,
	NumberInput,
	Select,
	SelectItem,
	TextInput,
} from "@tremor/react";
import { addDays, addMonths, addYears, format } from "date-fns";
import { useEffect, useState } from "react";

import type { Package, Subscription } from "@/types";

const RequiredLabel = ({ children }) => (
	<span>
		{children} <span className="text-red-500">*</span>
	</span>
);

export const lengthTypesMap = {
	month: "Μηνιαίο",
	year: "Ετήσιο",
	demo: "Demo",
	open: "Ανοιχτό",
};

interface Product {
	id: string;
	name: string;
}

interface Organization {
	id: string;
	name: string;
	afm: string;
	contact_email: string;
	phone_number: string;
	customer_s1: string;
	address: string;
}

const AddOrganization = ({
	getOrgs,
	orgs,
}: {
	getOrgs: (
		isMounted?: boolean,
		controller?: AbortController,
	) => Promise<Organization[]>;
	orgs: Organization[];
}) => {
	// console.log("Organizations", orgs);
	const [organization, setOrganization] = useState("");
	const [selectedOrganization, setSelectedOrganization] = useState("");
	const [afm, setAfm] = useState("");
	const [afmError, setAfmError] = useState("");
	const [softoneCode, setSoftoneCode] = useState("");
	const [email, setEmail] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [telNumber, setTelNumber] = useState("");
	const [telNumberError, setTelNumberError] = useState("");
	const [address, setAddress] = useState("");
	const [allowedUsersNumber, setAllowedUsersNumber] = useState(0);
	const [allowedCreditsNumber, setAllowedCreditsNumber] = useState(0);
	const [products, setProducts] = useState<Product[]>();
	const [product, setProduct] = useState("");
	const [allowedCredits, setAllowedCredits] = useState(0);
	const [subscriptionLength, setSubscriptionLength] = useState("");
	const [subscriptionQuantity, setSubscriptionQuantity] = useState(1);
	const [startDate, setStartDate] = useState<string>(
		new Date().toISOString().split("T")[0],
	);
	const [endDate, setEndDate] = useState<string>("");
	const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

	const [packages, setPackages] = useState<Package[]>();
	const [selectedPackage, setSelectedPackage] = useState("");

	const [dateRange] = useState({
		from: new Date(),
		to: new Date(new Date().setDate(new Date().getDate() + 15) - 86400000), // return new Date with one year added and one day subtracted
		// selectValue: "demo",
	});
	const [note, setNote] = useState("");
	const [popOpen, setPopOpen] = useState(false);

	console.log("Called from  add");
	const axiosPrivate = useAxiosPrivate();

	const validateAndFormatTelNumber = (value: string) => {
		if (value === "") {
			setTelNumberError("");
			return value;
		}
		// Remove all non-digit characters
		const digitsOnly = value.replace(/\D/g, "");

		// if (digitsOnly.length == 10) {
		// 	setTelNumberError("Το τηλέφωνο πρέπει να έχει 10 ψηφία");
		// 	return value;
		// }

		// setTelNumberError("");
		// Format the number as XXX-XXX-XXXX
		// return digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
		return digitsOnly;
	};

	useEffect(() => {
		setAfm(
			orgs?.find((item) => item.id.toString() === selectedOrganization)?.afm ||
				"",
		);
		setContactEmail(
			orgs?.find((item) => item.id.toString() === selectedOrganization)
				?.contact_email || "",
		);
		setTelNumber(
			orgs?.find((item) => item.id.toString() === selectedOrganization)
				?.phone_number || "",
		);
		setSoftoneCode(
			orgs?.find((item) => item.id.toString() === selectedOrganization)
				?.customer_s1 || "",
		);
		setAddress(
			orgs?.find((item) => item.id.toString() === selectedOrganization)
				?.address || "",
		);
		setTelNumberError("");
		setAfmError("");
	}, [selectedOrganization]);

	useEffect(() => {
		setProduct(products?.[0] ? products[0].id.toString() : "");
	}, [products]);

	useEffect(() => {
		setSelectedPackage(packages?.[0] ? packages[0].id.toString() : "");
		setSubscriptionLength(
			packages?.[0] ? packages[0].details?.length_type?.[0]! : "",
		);
	}, [packages]);

	useEffect(() => {}, [packages]);
	useEffect(() => {
		// console.log(
		// 	"Allowed Credits",
		// 	packages?.find((item) => item.id.toString() === selectedPackage)?.details
		// 		.allowed_credits,
		// );
		setAllowedCredits(
			packages?.find((item) => item.id.toString() === selectedPackage)?.details
				.allowed_credits || 0,
		);
	}, [selectedPackage]);

	useEffect(() => {
		if (startDate && subscriptionLength) {
			let newEndDate = new Date(startDate);

			switch (subscriptionLength) {
				case "year":
					newEndDate = addYears(newEndDate, subscriptionQuantity);
					break;
				case "month":
					newEndDate = addMonths(newEndDate, subscriptionQuantity);
					break;
				case "demo":
					newEndDate = addDays(newEndDate, 15);
					break;
				case "open":
					// For 'open' subscription, we don't set an end date
					setEndDate("");
					return;
			}

			// Subtract one day to get the last day of the subscription
			newEndDate = addDays(newEndDate, -1);
			setEndDate(format(newEndDate, "yyyy-MM-dd"));
		}
	}, [subscriptionLength, startDate, subscriptionQuantity]);

	useEffect(() => {
		const newSubscriptions: Subscription[] = [];
		for (let i = 0; i < subscriptionQuantity; i++) {
			const subscriptionObj = {
				package_id: selectedPackage,
				start_date: startDate,
				end_date: endDate,
				allowed_credits: allowedCredits,
			};
			if (i > 0) {
				if (subscriptionLength === "year") {
					subscriptionObj.start_date = format(
						addYears(new Date(subscriptionObj.start_date), i),
						"yyyy-MM-dd",
					);
					subscriptionObj.end_date = format(
						addYears(new Date(subscriptionObj.end_date), i),
						"yyyy-MM-dd",
					);
				} else if (subscriptionLength === "month") {
					subscriptionObj.start_date = format(
						addMonths(new Date(subscriptionObj.start_date), i),
						"yyyy-MM-dd",
					);
					subscriptionObj.end_date = format(
						addMonths(new Date(subscriptionObj.end_date), i),
						"yyyy-MM-dd",
					);
				}
			}
			newSubscriptions.push(subscriptionObj);
		}
		// console.log("New Subscriptions", newSubscriptions);
		setSubscriptions(newSubscriptions);
	}, [
		subscriptionQuantity,
		selectedPackage,
		startDate,
		endDate,
		allowedCredits,
		subscriptionLength,
	]);

	useEffect(() => {
		setAllowedCreditsNumber(allowedCredits);
	}, [allowedCredits]);

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		if (product) {
			getPackagesForProduct(isMounted, controller);
		}

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [product]);

	async function handleSubmit(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		if (!validateAFM(afm)) {
			setAfmError("Το ΑΦΜ δεν είναι έγκυρο");
			return;
		}

		try {
			await addOrganization(
				{
					email,
					contactEmail,
					telNumber,
					afm,
					softoneCode,
					address,
					organization: organization.trim(),
					allowedUsersNumber,
					allowedCreditsNumber,
					dateRange,
					product,
					subscriptions,
					note: note.trim(),
				},
				controller?.signal,
			);

			await getOrgs(isMounted, controller);
		} catch (error) {
			console.error(error);
			// //navigate("/login", { state: { from: location }, replace: true });
		} finally {
			setPopOpen(false);
			setEmail("");
			setContactEmail("");
			setTelNumber("");
			setSoftoneCode("");
			setAfm("");
			setAddress("");
			setOrganization("");
			setAllowedUsersNumber(0);
			setAllowedCreditsNumber(0);
		}
	}

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		const getdata = async () => {
			try {
				const response = await axiosPrivate.get(`/auth/products`);
				// console.log("Products", response.data.products);

				if (response.data.products) {
					isMounted && setProducts(response.data.products);
				}
			} catch (error) {
				console.error(error);
				// //navigate("/login", { state: { from: location }, replace: true });
			}

			return () => {
				isMounted = false;
				controller.abort();
			};
		};

		getdata();
	}, []);

	async function getPackagesForProduct(
		isMounted = true,
		controller = new AbortController(),
	) {
		try {
			const response = await axiosPrivate.get(
				`/auth/packages/product/${Number.parseInt(product!)}`,
				{
					signal: controller.signal,
				},
			);

			isMounted && setPackages(response.data.packages);
		} catch (error) {
			console.error(error);
			// //navigate("/login", { state: { from: location }, replace: true });
		}
	}
	return (
		<>
			<Button
				className="ml-2"
				onClick={() => {
					setPopOpen(true);
				}}
			>
				Προσθήκη
			</Button>

			<Dialog
				open={popOpen}
				onOpenChange={() => {
					setPopOpen(!popOpen);
					// clear form fields and error messages
					setOrganization("");
					setSelectedOrganization("");
					setAfm("");
					setSoftoneCode("");
					setEmail("");
					setContactEmail("");
					setTelNumber("");
					setAddress("");
					setProduct("");
					setSelectedPackage("");
					setAllowedCredits(0);
					setSubscriptionLength("");
					setSubscriptionQuantity(1);
					setStartDate(new Date().toISOString().split("T")[0]);
					setEndDate("");
					setNote("");
					setAfmError("");
					setTelNumberError("");
				}}
			>
				<DialogTrigger
				// onClick={() => {
				// 	setPopOpen(!popOpen);
				// }}
				></DialogTrigger>
				<DialogContent
					className="w-[95vw] max-w-[600px] max-h-[90vh] overflow-y-auto"
					onPointerDownOutside={(e) => {
						e.preventDefault();
					}}
				>
					<DialogHeader>
						<DialogTitle>Προσθήκη Οργανισμού</DialogTitle>
					</DialogHeader>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
						className="space-y-4"
					>
						<div className="grid grid-cols-1 gap-4">
							<div>
								<label
									htmlFor="organization"
									className="block text-sm font-medium text-gray-700"
								>
									<RequiredLabel>Οργανισμός</RequiredLabel>
								</label>
								<TextInput
									id="organization"
									placeholder="Ονομασία Οργανισμού"
									value={organization}
									onValueChange={setOrganization}
									required
								/>
							</div>
							<div>
								<label
									htmlFor="existingOrg"
									className="block text-sm font-medium text-gray-700"
								>
									Αντιγραφή Στοιχείων από Υπάρχοντα Οργανισμό
								</label>
								<Select
									id="existingOrg"
									value={selectedOrganization}
									onValueChange={setSelectedOrganization}
									enableClear={true}
								>
									<SelectItem value="">Επιλέξτε Οργανισμό</SelectItem>
									{orgs?.map((org) => (
										<SelectItem
											key={org.id.toString()}
											value={org.id.toString()}
										>
											{org.name}
										</SelectItem>
									))}
								</Select>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-4">
							<div>
								<label
									htmlFor="contactEmail"
									className="block text-sm font-medium text-gray-700"
								>
									<RequiredLabel>Email Επικοινωνίας</RequiredLabel>
								</label>
								<TextInput
									id="contactEmail"
									type="email"
									placeholder="Email Επικοινωνίας"
									value={contactEmail}
									onValueChange={(value) => setContactEmail(value.trim())}
									required
								/>
							</div>
							<div>
								<label
									htmlFor="telNumber"
									className="block text-sm font-medium text-gray-700"
								>
									<RequiredLabel>Τηλέφωνο</RequiredLabel>
								</label>
								<TextInput
									id="telNumber"
									placeholder="Τηλ. Επικοινωνίας"
									value={telNumber}
									onValueChange={(value) => {
										const formattedValue = validateAndFormatTelNumber(value);
										setTelNumber(formattedValue);
									}}
									error={telNumberError !== ""}
									errorMessage={telNumberError}
									required
								/>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-4">
							<div>
								<label
									htmlFor="afm"
									className="block text-sm font-medium text-gray-700"
								>
									ΑΦΜ
								</label>
								<TextInput
									id="afm"
									placeholder="ΑΦΜ"
									value={afm}
									error={afmError !== ""}
									errorMessage={afmError}
									onValueChange={(value) => {
										setAfm(value);
										setAfmError(
											validateAFM(value) ? "" : "Το ΑΦΜ δεν είναι έγκυρο",
										);
									}}
								/>
							</div>
							<div>
								<label
									htmlFor="address"
									className="block text-sm font-medium text-gray-700"
								>
									Διεύθυνση
								</label>
								<TextInput
									id="address"
									placeholder="Διεύθυνση"
									value={address}
									onValueChange={setAddress}
								/>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-4">
							<div>
								<label
									htmlFor="softoneCode"
									className="block text-sm font-medium text-gray-700"
								>
									Κωδ. SoftOne
								</label>
								<TextInput
									id="softoneCode"
									placeholder="Κωδ. SoftOne"
									value={softoneCode}
									onValueChange={setSoftoneCode}
								/>
							</div>
							<div>
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Email Admin του Οργανισμού
								</label>
								<TextInput
									id="email"
									type="email"
									placeholder="email"
									value={email}
									onValueChange={(value) => setEmail(value.trim())}
									required
								/>
							</div>
						</div>

						<Divider />

						<div className="grid grid-cols-2 gap-4">
							<div>
								<label
									htmlFor="product"
									className="block text-sm font-medium text-gray-700"
								>
									<RequiredLabel>Προϊόν</RequiredLabel>
								</label>
								<Select id="product" value={product} onValueChange={setProduct}>
									{products?.map((prod) => (
										<SelectItem
											key={prod.id.toString()}
											value={prod.id.toString()}
										>
											{prod.name}
										</SelectItem>
									))}
								</Select>
							</div>
							<div>
								<label
									htmlFor="subscriptionType"
									className="block text-sm font-medium text-gray-700"
								>
									Πακέτο
								</label>
								<Select
									id="subscriptionType"
									value={selectedPackage}
									onValueChange={setSelectedPackage}
								>
									{packages?.map((pkg) => (
										<SelectItem
											key={pkg.id.toString()}
											value={pkg.id.toString()}
										>
											{pkg.name}
										</SelectItem>
									))}
								</Select>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-4">
							<div>
								<label
									htmlFor="allowedCredits"
									className="block text-sm font-medium text-gray-700"
								>
									Επιτρεπόμενα Credits
								</label>
								<NumberInput
									id="allowedCredits"
									placeholder="Αριθμός Credits"
									value={allowedCredits}
									onValueChange={setAllowedCredits}
								/>
							</div>
							<div>
								<label
									htmlFor="subscriptionLength"
									className="block text-sm font-medium text-gray-700"
								>
									Διάρκεια Συνδρομής
								</label>
								<Select
									id="subscriptionLength"
									value={subscriptionLength}
									onValueChange={setSubscriptionLength}
								>
									{packages
										?.find((item) => item.id.toString() === selectedPackage)
										?.details?.length_type?.map((k) => (
											<SelectItem key={k} value={k}>
												{lengthTypesMap[k]}
											</SelectItem>
										))}
								</Select>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-4">
							<div>
								<label
									htmlFor="startDate"
									className="block text-sm font-medium text-gray-700"
								>
									Ημερομηνία Έναρξης
								</label>
								<DatePicker
									selected={startDate ? new Date(startDate) : undefined}
									onChange={(date) =>
										setStartDate(date ? date.toISOString().split("T")[0] : "")
									}
									locale="el"
									dateFormat="dd/MM/yyyy"
									placeholderText="Επιλέξτε Ημερομηνία"
									showYearDropdown
									yearDropdownItemNumber={40}
									scrollableYearDropdown
									className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
								/>
							</div>
							{endDate && (
								<div>
									<label
										htmlFor="endDate"
										className="block text-sm font-medium text-gray-700"
									>
										Ημερομηνία Λήξης
									</label>

									<DatePicker
										selected={endDate ? new Date(endDate) : undefined}
										locale="el"
										dateFormat="dd/MM/yyyy"
										placeholderText="Επιλέξτε Ημερομηνία"
										showYearDropdown
										yearDropdownItemNumber={40}
										scrollableYearDropdown
										readOnly
										className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
									/>
								</div>
							)}
						</div>

						{endDate && (
							<div>
								<label
									htmlFor="subscriptionQuantity"
									className="block text-sm font-medium text-gray-700"
								>
									Ποσότητα Συνδρομών
								</label>
								<NumberInput
									id="subscriptionQuantity"
									placeholder="Ποσότητα"
									value={subscriptionQuantity}
									onValueChange={setSubscriptionQuantity}
									min={1}
								/>
							</div>
						)}

						<div>
							<label
								htmlFor="subscriptionNote"
								className="block text-sm font-medium text-gray-700"
							>
								Σημείωση
							</label>
							<TextInput
								id="subscriptionNote"
								placeholder="Σημείωση"
								value={note}
								onValueChange={setNote}
							/>
						</div>

						<div className="flex justify-center space-x-4">
							<Button
								color="emerald"
								icon={UserAddIcon}
								disabled={
									contactEmail === "" ||
									organization === "" ||
									telNumber === "" ||
									endDate === ""
								}
								tooltip={
									organization === ""
										? "Η ονομασία είναι υποχρεωτική"
										: contactEmail === ""
											? "Το email είναι υποχρεωτικό"
											: telNumber === ""
												? "Το τηλέφωνο είναι υποχρεωτικό"
												: endDate === ""
													? "Δεν έχουν συμπληρωθεί όλα τα πεδία της Συνδρομής"
													: ""
								}
								type="submit"
								className="w-2/3"
							>
								Προσθήκη
							</Button>
							{/* Ακύρωση button */}
							<Button
								color="red"
								onClick={() => {
									setEmail("");
									setContactEmail("");
									setTelNumber("");
									setSoftoneCode("");
									setAfm("");
									setAddress("");
									setOrganization("");
									setAllowedUsersNumber(0);
									setAllowedCreditsNumber(0);
									setPopOpen(false);
								}}
								className="w-1/3"
							>
								Ακύρωση
							</Button>
						</div>
					</form>
				</DialogContent>
			</Dialog>
			{/* </Button> */}
		</>
	);
};

export default AddOrganization;
