import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

import useAxiosPrivate from "@/hooks/useAxiosPrivate";

import { UserAddIcon } from "@heroicons/react/outline";

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Select, SelectItem, TextInput } from "@tremor/react";
import { toast } from "sonner";

const AddOrganization = ({
	getUsers,
	org_id,
}: {
	getUsers: any;
	org_id: any;
}) => {
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("user");
	const [popOpen, setPopOpen] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	async function handleSubmit(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			await axiosPrivate.post(
				`/auth/users`,
				{ email, org_id, role, is_active: true },
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
					signal: controller?.signal,
				},
			);

			await getUsers(isMounted, controller);
		} catch (error: any) {
			if (error.response.status === 409) {
				toast.error(
					"Δεν μπορεί να γίνει προσθήκη χρήστη. Το email χρησιμοποιείται ήδη.",
				);
			} else if (error.response.status === 400) {
				toast.error(error.response.data.message);
			} else {
				navigate("/login", { state: { from: location }, replace: true });
			}
		} finally {
			setEmail("");
			setPopOpen(false);
		}
	}

	return (
		<Popover open={popOpen}>
			<Button
				className="ml-2"
				icon={UserAddIcon}
				onClick={() => {
					setPopOpen(!popOpen);
				}}
			>
				<PopoverTrigger>Προσθήκη Νέου Χρήστη</PopoverTrigger>
			</Button>
			<PopoverContent
				onPointerDownOutside={() => {
					setPopOpen(false);
				}}
				className="w-80 mt-4"
			>
				<div className="grid gap-4">
					<div className="space-y-2">
						<h4 className="font-medium leading-none">Προσθήκη Χρήστη</h4>
						<p className="text-sm text-muted-foreground">
							Καταχωρείστε το email του νέου χρήστη.
						</p>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="space-y-2">
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="height"
										className="text-sm font-medium text-muted-foreground"
									>
										Email
									</label>
									<TextInput
										id="email"
										type="email"
										placeholder="email"
										value={email}
										required
										autoComplete="email"
										onValueChange={(value) => {
											setEmail(value);
										}}
									/>
								</div>
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="role"
										className="text-sm font-medium text-muted-foreground"
									>
										Ρόλος
									</label>
									<Select
										id="role"
										value={role}
										onValueChange={(value) => {
											setRole(value);
										}}
									>
										<SelectItem value="user">Χρήστης</SelectItem>
										<SelectItem value="admin">Διαχειριστής</SelectItem>
									</Select>
								</div>

								<Button color="emerald" type="submit" className="w-full">
									Προσθήκη
								</Button>
							</div>
						</form>
					</div>
				</div>
			</PopoverContent>
		</Popover>
	);
};

export default AddOrganization;
