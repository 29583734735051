import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { formatDate } from "@/utils";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import useAppStore from "@/store/AppStore";
import {
	Badge,
	Button,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeaderCell,
	TableRow,
	Text,
	TextInput,
} from "@tremor/react";
import { ChevronDownIcon } from "lucide-react";
import AddPackage from "./AddPackage";

const lengthTypesMap = {
	month: "Μηνιαίο",
	year: "Ετήσιο",
	demo: "Demo",
	open: "Ανοιχτό",
};

const frequencyMap = {
	weekly: "Εβδομαδιαίο",
	24: "24 ώρες",
	48: "48 ώρες",
	adhoc: "Χειροκίνητα",
};

type LengthType = "month" | "year" | "demo" | "open";

type FrequencyType = "weekly" | "24" | "48" | "adhoc";

interface Package {
	id: string;
	name: string;
	details: {
		allowed_credits?: number;
		allowed_users?: number;
		length_type?: LengthType[];
		frequency?: FrequencyType;
		freq_editable?: boolean;
	};
}

interface Product {
	id: string;
	name: string;
	description: string;
	price: number;
	credits: number;
	status: "active" | "inactive";
	created_at: string;
}

const SingleProductPage: React.FC = () => {
	const { product_id } = useParams<{ product_id: string }>();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	const [product, setProduct] = useState<Product | null>(null);
	const [packages, setPackages] = useState<Package[]>([]);
	const [editMode, setEditMode] = useState(false);

	const [tab, setTab] = useState("packages");
	const appStore = useAppStore() as { user: { role: string } };

	const fetchProduct = async () => {
		try {
			const response = await axiosPrivate.get(
				`/auth/products/${Number.parseInt(product_id!)}`,
			);
			// console.log("response", response);
			setProduct(response.data.product);
		} catch (error) {
			console.error("Error fetching product:", error);
			toast.error("Failed to fetch product details");
			navigate("/products");
		}
	};
	const fetchPackages = useCallback(async () => {
		try {
			const response = await axiosPrivate.get(
				`/auth/packages/product/${Number.parseInt(product_id!)}`,
			);
			// console.log("Packages", response.data.packages);
			setPackages(response.data.packages);
		} catch (error) {
			console.error("Error fetching packages:", error);
			toast.error("Failed to fetch packages");
		}
	}, [axiosPrivate, product_id]);

	useEffect(() => {
		fetchProduct();
		fetchPackages();
	}, [product_id, fetchPackages]);

	const handleInputChange = (field: keyof Product, value: string | number) => {
		setProduct((prev) => (prev ? { ...prev, [field]: value } : null));
	};

	const handleSave = async () => {
		try {
			await axiosPrivate.put(`/auth/products/${product_id}`, product);
			setEditMode(false);
			toast.success("Product updated successfully");
		} catch (error) {
			console.error("Error updating product:", error);
			toast.error("Failed to update product");
		}
	};

	const handleAddPackage = async (newPackage: Omit<Package, "id">) => {
		try {
			await axiosPrivate.post(`/auth/packages`, {
				product_id: product_id,
				name: newPackage.name,
				details: newPackage.details,
			});
			toast.success("Package added successfully");
			fetchPackages(); // Refetch packages after successful addition
		} catch (error: any) {
			console.error("Error adding package:", error);
			if (error.status === 400) {
				toast.error(error.response.data);
				return;
			}
			toast.error("Failed to add package");
		}
	};
	const handleDeletePackage = async (packageId: string) => {
		const isConfirmed = window.confirm(
			"Are you sure you want to delete this package?",
		);
		if (!isConfirmed) return;

		try {
			await axiosPrivate.delete(`/auth/packages/${packageId}`);
			setPackages((prev) => prev.filter((pkg) => pkg.id !== packageId));

			toast.success("Package deleted successfully");
		} catch (error) {
			console.error("Error deleting package:", error);
			toast.error("Failed to delete package");
		}
	};

	if (!product) {
		return <div>Loading...</div>;
	}

	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div id="product-header">
				<div className="flex items-center justify-between space-y-2">
					<h2 className="text-3xl font-bold tracking-tight">
						{editMode ? (
							<TextInput
								value={product.name}
								onChange={(e) => handleInputChange("name", e.target.value)}
								placeholder="Product Name"
							/>
						) : (
							product.name
						)}
					</h2>
					{/* {appStore.user.role === "superAdmin" && (
						<Button
							onClick={() => setEditMode(!editMode)}
							color={editMode ? "red" : "blue"}
						>
							{editMode ? "Ακύρωση" : "Επεξεργασία"}
						</Button>
					)} */}
				</div>
				<div className="mt-4">
					<div>
						<div className="grid grid-cols-2 gap-4">
							<div>
								<h3 className="text-sm font-medium text-gray-500">
									Ημερομηνία Δημιουργίας
								</h3>
								<p className="text-lg font-semibold text-gray-900">
									{formatDate(product.created_at)}
								</p>
							</div>
							<div>
								<h3 className="text-sm font-medium text-gray-500">Περιγραφή</h3>
								{editMode ? (
									<TextInput
										value={product.description}
										onChange={(e) =>
											handleInputChange("description", e.target.value)
										}
										placeholder="Description"
									/>
								) : (
									<p className="text-lg font-semibold text-gray-900">
										{product.description}
									</p>
								)}
							</div>
						</div>
					</div>
				</div>
				{editMode && appStore.user.role === "superAdmin" && (
					<Button onClick={handleSave} color="green" className="mt-4">
						Αποθήκευση
					</Button>
				)}
			</div>

			<Tabs
				defaultValue="overview"
				value={tab}
				onValueChange={setTab}
				className="space-y-4"
			>
				{/* <TabsList>
					<TabsTrigger value="packages">Πακέτα</TabsTrigger>
				</TabsList> */}
				<TabsContent value="overview" className="space-y-4">
					{/* ... (keep the existing overview content) */}
				</TabsContent>
				<TabsContent value="packages" className="space-y-4">
					<Card>
						<CardHeader>
							<CardTitle>Πακέτα</CardTitle>
						</CardHeader>
						{appStore.user.role === "superAdmin" && (
							<div className="mt-4">
								<AddPackage onAddPackage={handleAddPackage} />
							</div>
						)}
						<CardContent>
							{packages?.length === 0 && appStore.user.role === "superAdmin" ? (
								<div className="text-center py-4">
									<Text>Δεν υπάρχουν διαθέσιμα πακέτα για αυτό το προϊόν.</Text>
									<AddPackage onAddPackage={handleAddPackage} />
								</div>
							) : (
								<>
									<Table>
										<TableHead>
											<TableRow>
												<TableHeaderCell>Name</TableHeaderCell>
												<TableHeaderCell>
													<div className="flex items-center">
														<span>Επιτρεπόμενα Credits</span>
														<ChevronDownIcon className="h-4 w-4 ml-1" />
													</div>
												</TableHeaderCell>
												<TableHeaderCell>Επιτρεπόμενοι Χρήστες</TableHeaderCell>
												<TableHeaderCell>Διάρκεια</TableHeaderCell>
												<TableHeaderCell>Συχν. Ελέγχου</TableHeaderCell>
												<TableHeaderCell>Επεξεργάσιμη Συχν.</TableHeaderCell>
												{appStore.user.role === "superAdmin" && (
													<TableHeaderCell>Actions</TableHeaderCell>
												)}
											</TableRow>
										</TableHead>
										<TableBody>
											{packages
												// sort by allowed_credits ascending

												?.sort((a, b) =>
													a.details.allowed_credits! >
													b.details.allowed_credits!
														? 1
														: -1,
												)
												?.map((pkg) => (
													<TableRow key={pkg.id}>
														<TableCell>{pkg.name}</TableCell>
														<TableCell>
															{pkg?.details?.allowed_credits}
														</TableCell>
														<TableCell>{pkg?.details?.allowed_users}</TableCell>
														<TableCell>
															{pkg?.details?.length_type?.map((item, index) => (
																<Badge key={index} className="mr-1">
																	{lengthTypesMap[item]}
																</Badge>
															))}
														</TableCell>
														<TableCell>
															<Badge
																color={
																	pkg?.details?.frequency === "weekly"
																		? "indigo"
																		: "green"
																}
															>
																{frequencyMap[pkg?.details?.frequency!]}
															</Badge>
														</TableCell>
														<TableCell>
															<Badge
																color={
																	pkg?.details?.freq_editable ? "green" : "red"
																}
															>
																{pkg?.details?.freq_editable ? "Ναι" : "Όχι"}
															</Badge>
														</TableCell>
														{appStore.user.role === "superAdmin" && (
															<TableCell>
																<Button
																	onClick={() => handleDeletePackage(pkg.id)}
																	color="red"
																>
																	Διαγραφή
																</Button>
															</TableCell>
														)}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</>
							)}
						</CardContent>
					</Card>
				</TabsContent>
			</Tabs>
		</div>
	);
};

export default SingleProductPage;
