import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { el } from "date-fns/locale";
registerLocale("el", el);

import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import {
	Button,
	NumberInput,
	SearchSelect,
	SearchSelectItem,
	Select,
	SelectItem,
} from "@tremor/react";
import axios from "axios";
import { addDays, addMonths, addYears, format } from "date-fns";
import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { lengthTypesMap } from "../AddOrganization/AddOrganization";

interface AddSubscriptionProps {
	productId: string;
	orgId: string;
	onSubscriptionAdded: () => void;
}

const AddSubscription: React.FC<AddSubscriptionProps> = ({
	productId,
	orgId,
	onSubscriptionAdded,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [newSubscription, setNewSubscription] = useState({
		package_id: "",
		start_date: "",
		end_date: "",
		allowed_credits: 0,
		allowed_users: 0,
	});
	const [lengthType, setLengthType] = useState("");
	const [subscriptionQuantity, setSubscriptionQuantity] = useState(1);
	const [packages, setPackages] = useState<any[]>([]);
	const [subscriptions, setSubscriptions] = useState<
		{
			package_id: string;
			start_date: string;
			end_date: string;
			allowed_credits: number;
		}[]
	>([]);

	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();

	async function getPackagesForProduct(
		isMounted = true,
		controller = new AbortController(),
	) {
		try {
			const response = await axiosPrivate.get(
				`/auth/packages/product/${Number.parseInt(productId!)}`,
				{
					signal: controller.signal,
				},
			);
			// console.log("Packages", response.data.packages);

			isMounted && setPackages(response.data.packages);
		} catch (error) {
			console.error(error);
			navigate("/login", { state: { from: location }, replace: true });
		}
	}

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		getPackagesForProduct(isMounted, controller);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	useEffect(() => {
		if (newSubscription.package_id) {
			const lengthType = packages?.find(
				(item) => item.id.toString() === newSubscription.package_id,
			)?.details?.length_type?.[0];
			setLengthType(lengthType);
			const defaultCredits = packages?.find(
				(item) => item.id.toString() === newSubscription.package_id,
			)?.details?.allowed_credits;

			setNewSubscription((prev) => ({
				...prev,
				allowed_credits: defaultCredits,
			}));
		}
	}, [newSubscription.package_id, packages]);

	useEffect(() => {
		if (newSubscription.start_date && lengthType) {
			let newEndDate = new Date(newSubscription.start_date);

			switch (lengthType) {
				case "year":
					newEndDate = addYears(newEndDate, 1);
					break;
				case "month":
					newEndDate = addMonths(newEndDate, 1);
					break;
				case "demo":
					newEndDate = addDays(newEndDate, 15);
					break;
				case "open":
					// For 'open' subscription, we don't set an end date
					setNewSubscription((prev) => ({
						...prev,
						end_date: "",
					}));
					return;
			}

			// Subtract one day to get the last day of the subscription
			newEndDate = addDays(newEndDate, -1);
			setNewSubscription((prev) => ({
				...prev,
				end_date: format(newEndDate, "yyyy-MM-dd"),
			}));
		}
	}, [newSubscription.start_date, lengthType]);
	const handleInputChange = (field: string, value: string | number) => {
		setNewSubscription((prev) => ({ ...prev, [field]: value }));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await axiosPrivate.post("/auth/subscriptions", {
				subscriptions,
				product_id: productId,
				org_id: orgId,
			});
			toast.success("Η συνδρομή προστέθηκε με επιτυχία");
			setIsOpen(false);
			onSubscriptionAdded();
		} catch (error) {
			console.error("Error adding subscription:", error);
			// Narrow down the type of error

			if (axios.isAxiosError(error)) {
				// Axios error
				toast.error(`${error.response?.data}`);
			} else if (error instanceof Error) {
				// General JavaScript error
				toast.error(`${error.message}`);
			} else {
				// Unknown error

				toast.error("Προέκυψε σφάλμα κατά την προσθήκη της συνδρομής.");
			}
		}
	};

	useEffect(() => {
		const newSubscriptions: {
			package_id: string;
			start_date: string;
			end_date: string;
			allowed_credits: number;
		}[] = [];
		for (let i = 0; i < subscriptionQuantity; i++) {
			const subscriptionObj = {
				package_id: newSubscription.package_id,
				start_date: newSubscription.start_date,
				end_date: newSubscription.end_date,
				allowed_credits: newSubscription.allowed_credits,
			};
			if (i > 0) {
				if (lengthType === "year") {
					subscriptionObj.start_date = format(
						addYears(new Date(subscriptionObj.start_date), i),
						"yyyy-MM-dd",
					);
					subscriptionObj.end_date = format(
						addYears(new Date(subscriptionObj.end_date), i),
						"yyyy-MM-dd",
					);
				} else if (lengthType === "month") {
					subscriptionObj.start_date = format(
						addMonths(new Date(subscriptionObj.start_date), i),
						"yyyy-MM-dd",
					);
					subscriptionObj.end_date = format(
						addMonths(new Date(subscriptionObj.end_date), i),
						"yyyy-MM-dd",
					);
				}
			}
			newSubscriptions.push(subscriptionObj);
		}
		// console.log("New Subscriptions", newSubscriptions);
		setSubscriptions(newSubscriptions);
	}, [
		subscriptionQuantity,
		newSubscription.package_id,
		newSubscription.start_date,
		newSubscription.end_date,
		newSubscription.allowed_credits,
		lengthType,
	]);

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger asChild>
				<Button onClick={() => setIsOpen(true)}>Προσθήκη Νέας Συνδρομής</Button>
			</PopoverTrigger>
			<PopoverContent className="w-fit mt-4">
				<form onSubmit={handleSubmit} className="space-y-4">
					<div className="flex flex-row justify-between space-x-1">
						<div className="flex flex-col items-start space-y-1">
							<label
								htmlFor="subscriptionType"
								className="text-sm font-medium text-muted-foreground"
							>
								Πακέτο
							</label>
							<SearchSelect
								value={newSubscription.package_id}
								onValueChange={(value) =>
									handleInputChange("package_id", value)
								}
							>
								{packages?.map(
									(item) =>
										item && (
											<SearchSelectItem
												key={item?.id}
												value={item?.id?.toString()}
											>
												{item?.name}
											</SearchSelectItem>
										),
								)}
							</SearchSelect>
						</div>
					</div>
					<div className="flex flex-row justify-between space-x-1">
						<div className="flex flex-col items-start space-y-1">
							<label
								htmlFor="allowedCredits"
								className="text-sm font-medium text-muted-foreground"
							>
								Επιτρεπόμενα Credits
							</label>
							<NumberInput
								id="allowedCredits"
								placeholder="Αριθμός Credits"
								value={newSubscription.allowed_credits}
								onValueChange={(value) => {
									handleInputChange("allowed_credits", value);
								}}
							/>
						</div>
						<div className="flex flex-col items-start space-y-1">
							<label
								htmlFor="allowedCredits"
								className="text-sm font-medium text-muted-foreground"
							>
								Διάρκεια Συνδρομής
							</label>
							<Select
								id="subscriptionLength"
								name="subscriptionLength"
								value={lengthType}
								onValueChange={(e) => {
									setLengthType(e);
								}}
								className="mt-2"
								enableClear={false}
							>
								{packages
									?.find(
										(item) => item.id.toString() === newSubscription.package_id,
									)
									?.details?.length_type?.map((k) => (
										<SelectItem key={k} value={k}>
											{lengthTypesMap[k]}
										</SelectItem>
									))}
							</Select>
						</div>
					</div>
					<div className="flex flex-row justify-start space-x-4">
						<div className="flex flex-col items-start space-y-1">
							<label
								htmlFor="subscriptionDuration"
								className="text-sm font-medium text-muted-foreground"
							>
								Ημερομηνία Έναρξης
							</label>
							<DatePicker
								selected={
									newSubscription.start_date
										? new Date(newSubscription.start_date)
										: undefined
								}
								onChange={(date) =>
									handleInputChange(
										"start_date",
										date ? date.toISOString().split("T")[0] : "",
									)
								}
								locale="el"
								dateFormat="dd/MM/yyyy"
								placeholderText="Επιλέξτε Ημερομηνία"
								showYearDropdown
								yearDropdownItemNumber={40}
								scrollableYearDropdown
								className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
							/>
						</div>
						{newSubscription.end_date && (
							<>
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="endDate"
										className="text-sm font-medium text-muted-foreground"
									>
										Ημερομηνία Λήξης
									</label>

									<DatePicker
										selected={
											newSubscription.end_date
												? new Date(newSubscription.end_date)
												: undefined
										}
										onChange={(date) =>
											handleInputChange(
												"end_date",
												date ? date.toISOString().split("T")[0] : "",
											)
										}
										locale="el"
										dateFormat="dd/MM/yyyy"
										placeholderText="Επιλέξτε Ημερομηνία"
										showYearDropdown
										yearDropdownItemNumber={40}
										scrollableYearDropdown
										readOnly
										className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
									/>
								</div>
								<div className="flex flex-col items-start space-y-1">
									<label
										htmlFor="subscriptionQuantity"
										className="text-sm font-medium text-muted-foreground"
									>
										Ποσότητα Συνδρομών
									</label>
									<NumberInput
										id="subscriptionQuantity"
										placeholder="Ποσότητα"
										value={subscriptionQuantity}
										onValueChange={(value) => {
											setSubscriptionQuantity(value);
										}}
										min={1}
									/>
								</div>
							</>
						)}
					</div>

					<Button type="submit">Προσθήκη Συνδρομής</Button>
				</form>
			</PopoverContent>
		</Popover>
	);
};

export default AddSubscription;
