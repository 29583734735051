import { useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
	const refresh = useRefreshToken();
	const { auth } = useAuth() as { auth: any };
	let isRefreshing = false;
	let newAccessToken = "";

	useEffect(() => {
		const requestIntercept = axiosPrivate.interceptors.request.use(
			(config) => {
				if (!config.headers.Authorization && auth?.accessToken) {
					config.headers.Authorization = `Bearer ${auth.accessToken}`;
				}
				return config;
			},
			(error) => Promise.reject(error),
		);

		const responseIntercept = axiosPrivate.interceptors.response.use(
			(response) => response,
			async (error) => {
				const prevRequest = error?.config;
				if (
					(error?.response?.status === 403 ||
						error?.response?.status === 401) &&
					!prevRequest?.sent
				) {
					if (isRefreshing === false) {
						isRefreshing = true;
						prevRequest.sent = true;

						try {
							newAccessToken = await refresh();
							prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
							return axiosPrivate(prevRequest);
						} catch (refreshError) {
							// Handle refresh error (e.g., redirect to login)
							return Promise.reject(refreshError);
						}
					} else {
						prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;

						return axiosPrivate(prevRequest);
					}
				}
				return Promise.reject(error);
			},
		);

		return () => {
			axiosPrivate.interceptors.request.eject(requestIntercept);
			axiosPrivate.interceptors.response.eject(responseIntercept);
		};
	}, [auth, refresh]);

	return axiosPrivate;
};

export default useAxiosPrivate;
